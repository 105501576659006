export default {
  namespaced: true,
  state: {
    scopes: [
      {
        text: "permission.role.form.fields.scope.user",
        value: "user"
      },
      {
        text: "permission.role.form.fields.scope.service",
        value: "service"
      }
    ]
  },
  getters: {
    scopes: (state) => {
      return state.scopes;
    },
  }
};
