import VueI18n from 'vue-i18n'
import Vue from 'vue';

import fr from "./../i18n/fr";
import en from "./../i18n/en";


Vue.use(VueI18n);

// i18n 
const i18n = new VueI18n({
  locale: 'fr',
  messages: {fr, en},
  fallbackLocale: 'fr',
})


export default i18n;