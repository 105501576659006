import i18n from "./../../i18n";

export default {
  namespaced: true,
  state: {
    eventTypes: [
      { text: 'EMAIL', value: 'EMAIL' }
    ],
    eventSignals: [
      { value: 'POST', text: i18n.t('forms.events.fields.signal.post') },
      { value: 'PRE', text: i18n.t('forms.events.fields.signal.pre') },
    ]
  },
  getters: {
    eventTypes: state => {
      return state.eventTypes;
    },
    eventSignals: state => {
      return state.eventSignals
    }
  }
}