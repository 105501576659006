import Vue from "vue";
import {
  SOCKET_IS_CONNECTED,
  SOCKET_IS_AUTHENTICATED
} from "./../mutations-types";

export default {
  state: {
    socketIsConnected: false,
    socketIsAuthenticated: false
  },
  getters: {
    socketIsConnected: state => {
      return state.socketIsConnected;
    },
    socketIsAuthenticated: state => {
      return state.socketIsAuthenticated;
    }
  },
  mutations: {
    [SOCKET_IS_CONNECTED]: (state, status) => {
      state.socketIsConnected = status;
    },
    [SOCKET_IS_AUTHENTICATED]: (state, status) => {
      state.socketIsAuthenticated = status;
    }
  },
  actions: {
    SOCKET_connect(store) {
      store.commit(SOCKET_IS_CONNECTED, true);
      const tokens = Vue.prototype.$auth.token();
      if (tokens) {
        const token = tokens.split("||")[0];
        this._vm.$socket.emit("authenticate", { token: token });
      }
    },
    SOCKET_disconnect(store) {
      store.commit(SOCKET_IS_CONNECTED, false);
      store.commit(SOCKET_IS_AUTHENTICATED, false);
    },
    SOCKET_authenticated(store, data) {
      if (data.status === "success") {
        store.commit(SOCKET_IS_AUTHENTICATED, true);
      } else {
        store.commit(SOCKET_IS_AUTHENTICATED, false);
      }
    }
  }
};
