import Vue from "vue";
import autolinker from 'autolinker'

const AutoLinker = {};

AutoLinker.install = function(Vue) {
  if (!Vue.$autolinker) {
    Vue.$autolinker = autolinker;
    Object.defineProperties(Vue.prototype, {
      $autolinker: {
        get() {
          return autolinker;
        }
      }
    });
  }
};

Vue.use(AutoLinker);
