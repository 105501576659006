import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "./../views/layout.vue";
import PageNotFound from "./../views/errors/404.vue";
import Account from "./../views/account/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./../views/home.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/",
    component: Layout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "forms",
        name: "forms",
        component: () => import("./../views/forms/index.vue"),
        meta: {
          permission: {
            resource: "form.list",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "forms/add",
        name: "forms-add",
        component: () => import("./../views/forms/new.vue"),
        meta: {
          permission: {
            resource: "form.create",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "forms/edit/:id",
        name: "forms-edit",
        component: () => import("./../views/forms/edit.vue"),
        meta: {
          permission: {
            resource: "form.update",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "model-data",
        name: "model-data",
        component: () => import("./../views/forms/model/index.vue"),
        meta: {
          permission: {
            resource: "model-field.list",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "permissions",
        name: "permission",
        component: () => import("./../views/permissions/index.vue"),
        meta: {
          permission: {
            resource: "permission.get",
            scope: "user",
          },
        },
      },
      {
        path: "services",
        name: "service",
        component: () => import("./../views/services/index.vue"),
        meta: {
          permission: {
            resource: "service.get",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "rate-plan",
        name: "rate-plan",
        component: () => import("./../views/rate-plans/index.vue"),
        meta: {
          permission: {
            resource: "rate-plan.get",
            scope: "user",
          },
        },
      },
      {
        path: "provisions",
        name: "provision",
        component: () => import("./../views/provisions/index.vue"),
        meta: {
          permission: {
            resource: "service.provision.get",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "users",
        name: "user",
        component: () => import("./../views/users/index.vue"),
        meta: {
          permission: {
            resource: "user.get",
            scope: "user",
          },
        },
      },
      {
        path: "account",
        component: Account,
        children: [
          {
            path: "",
            name: "account",
            component: () => import("./../views/account/profile.vue"),
          },
          {
            path: "profile",
            name: "profile",
            component: () => import("./../views/account/profile.vue"),
          },
          {
            path: "security",
            name: "security",
            component: () => import("./../views/account/security.vue"),
          },
          {
            path: "setting",
            name: "setting",
            component: () => import("./../views/account/setting.vue"),
          },
        ],
      },
      {
        path: "sla",
        name: "sla",
        component: () => import("./../views/sla/index.vue"),
      },
      {
        path: "working-time",
        name: "working-time",
        component: () => import("./../views/working-time/index.vue"),
        meta: {
          permission: {
            resource: "holiday.get",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "working-time.get",
        name: "holiday-time",
        component: () => import("./../views/holidays/index.vue"),
        meta: {
          permission: {
            resource: "holiday.get",
            scope: ["user", "service"],
          },
        },
      },
      {
        path: "statistics",
        component: () => import("./../views/statistics/index.vue"),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "",
            name: "statistic-message",
            component: () => import("./../views/statistics/message.vue"),
            meta: {
              permission: {
                resource: "message.statistic",
                scope: ["user", "service"],
              },
            },
          },
          {
            path: "tickets",
            name: "statistic-ticket",
            component: () => import("./../views/statistics/ticket.vue"),
            meta: {
              permission: {
                resource: "ticket.statistic",
                scope: ["user", "service"],
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("./../views/chat/index.vue"),
    meta: {
      auth: true,
      permission: {
        resource: "conversation.get",
        scope: ["user", "service"],
      },
    },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: () => import("./../views/tickets/index.vue"),
    meta: {
      auth: true,
      permission: {
        resource: "ticket.list",
        scope: ["user", "service"],
      },
    },
  },
  {
    path: "/login",
    component: () => import("./../views/security/login.vue"),
    name: "login",
  },
  {
    path: "/logout",
    component: () => import("./../views/security/logout.vue"),
    name: "logout",
  },
  {
    path: "/404",
    component: PageNotFound,
    name: "404",
  },
  {
    path: "/403",
    component: () => import("./../views/errors/403.vue"),
    name: "403",
  },
  {
    path: "*",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

Vue.router = router;

export default router;
