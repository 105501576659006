export default {
  namespaced: true,
  state: {
    notificationTypes: ["@OPENING", "@CLOSING", "@JOINING"]
  },
  getters: {
    notificationTypes: state => {
      return state.notificationTypes;
    }
  }
};
