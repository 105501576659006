import i18n from "./../../i18n";

export default {
  namespaced: true,
  state: {
    operations: [
      { text: i18n.t("provisions.fields.operation.credit"), value: "CREDIT" },
      { text: i18n.t("provisions.fields.operation.debit"), value: "DEBIT" },
    ],
  },
  getters: {
    operations: (state) => {
      return state.operations;
    },
  },
};
