import i18n from './../../i18n'; 

export default {
  namespaced: true,
  state: {
    genders: [
      { text: i18n.t('contacts.fields.gender.male'), value: 'male' },
      { text: i18n.t('contacts.fields.gender.female'), value: 'female' }
    ]
  },
  getters: {
    genders: state => {
      return state.genders
    }
  }
}