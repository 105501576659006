import Vue from "vue";
import Vuex from "vuex";
import builder from './modules/builder';
import notification from "./modules/notification";
import conversation from "./modules/conversation";
import message from "./modules/message";
import permission from "./modules/permission";
import channel from './modules/channel';
import settings from './modules/settings';
import user from './modules/user';
import service from './modules/service';
import provision from './modules/provision';
import contact from './modules/contact';
import forms from './modules/forms';
import ticket from './modules/ticket';

import socket from "./modules/socket";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  actions: {
    async request(store, params) {
      return Vue.axios[params.instance ? params.instance : "api"].request(
        params
      );
    }
  },
  modules: {
    notification: notification,
    conversation: conversation,
    permission: permission,
    message: message,
    socket: socket,
    channel: channel,
    settings: settings,
    user: user,
    service: service,
    provision: provision,
    contact: contact,
    forms: forms,
    builder: builder,
    ticket: ticket
  }
});
