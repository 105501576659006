export default {
  request: function(req, tokens) {
    if (typeof tokens !== "undefined") {
      let tokensArray = tokens.split('||');
      this.drivers.http.setHeaders.call(this, req, {
        Authorization: "Bearer " + tokensArray[0]
      });
    }
  },
  response: function(res) {
    if (res.data) {
      // return token and refresh token
      if (res.data.access_token && res.data.refresh_token) {
        const tokens = res.data;
        return `${tokens.access_token}||${tokens.refresh_token}`;
      }
        
    }
  }
};
