import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./../store";
import i18n from "./../i18n";

axios.defaults.headers.post["Content-Type"] = "application/json";

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
});
const defaultAxios = axios.create();

const instances = {
  default: defaultAxios,
  api: apiAxios
};

Vue.use(VueAxios, instances);

// interceptors
Vue.axios.api.interceptors.response.use(
  function(response) {
    const config = response.config;
    const messages = config.messages;
    const status = response.status;
    if (messages) {
      if (messages[status]) {
        if (typeof messages[status] === "string") {
          store.dispatch("notification/notify", {
            message: messages[status]
          });
        } else if (typeof messages[status] === "function") {
          store.dispatch("notification/notify", {
            message: messages[status](response.data)
          });
        }
      }
    }
    return response;
  },
  function(error) {
    const config = error.config;
    const messages = config.messages;
    if (error && error.response) {
      const status = error.response.status;
      if (messages) {
        if (messages[status]) {
          if (typeof messages[status] === "boolean") {
            store.dispatch("notification/notify", {
              message: i18n.t(`error_codes.${status}`)
            });
          } else if (typeof messages[status] === "string") {
            store.dispatch("notification/notify", {
              message: messages[status]
            });
          } else if (typeof messages[status] === "function") {
            const message = messages[status](error.response.data);
            if (message) {
              store.dispatch("notification/notify", {
                message: messages[status](error.response.data)
              });
            }
          }
        }
      }
    }
    return Promise.reject(error);
  }
);
