export default {
  namespaced: true,
  state: {
    avatar: {
      WHATSAPP: {
        icon: {
          value: "mdi-whatsapp",
          class: "whatsapp"
        }
      },
      MESSENGER: {
        icon: {
          value: "mdi-facebook-messenger",
          class: "messenger"
        }
      },
      EMAIL: {
        icon: {
          value: "mdi-email-outline",
          class: "email"
        }
      },
      SMS: {
        icon: {
          value: "mdi-message-text-outline",
          class: "sms"
        }
      },
      CALL: {
        icon: {
          value: "mdi-phone-outline",
          class: "call"
        }
      },
      default: {
        icon: {
          value: "mdi-message-text-outline",
          class: "sms"
        }
      }
    }
  },
  getters: {
    avatar: state => {
      return state.avatar;
    }
  },
  mutations: {},
  actions: {}
};
