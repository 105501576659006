export default {
  namespaced: true,
  state: {
    fields: {
      text: {
        type: "text",
        name: null,
        title: "Question ?",
        isRequired: false,
        isVisible: true,
        readOnly: false,
        placeholder: null,
        validators: [],
        visibleIf: []
      },
      textarea: {
        type: "textarea",
        name: null,
        title: "Question ?",
        isRequired: false,
        isVisible: true,
        readOnly: false,
        placeholder: null,
        validators: [],
        visibleIf: [],
      },
      select: {
        type: "select",
        name: null,
        title: "Question ?",
        isRequired: false,
        isVisible: true,
        readOnly: false,
        multiple: false,
        placeholder: null,
        validators: [],
        visibleIf: [],
        items: [{ text: "Option 0", value: "Option0" }],
        format: "select"
      },
      datetime: {
        type: "datetime",
        name: null,
        title: "Question ?",
        isRequired: false,
        isVisible: true,
        readOnly: false,
        placeholder: null,
        validators: [],
        visibleIf: [],
        format: "datetime",
      },
      model: {
        type: "model",
        name: null,
        title: "Question ?",
        isRequired: false,
        isVisible: true,
        readOnly: false,
        placeholder: null,
        validators: [],
        visibleIf: [],
        hash: null,
        parents: []
      },
    },
    types: [
      { value: "text", text: "forms.builder.fields.text.title" },
      { value: "textarea", text: "forms.builder.fields.textarea.title" },
      { value: "select", text: "forms.builder.fields.select.title" },
      { value: "datetime", text: "forms.builder.fields.datetime.title" },
      { value: "model", text: "forms.builder.fields.model.title" },
    ],
    iconTypes: {
      text: "mdi-text-short",
      textarea: "mdi-text",
      select: "mdi-radiobox-marked",
      datetime: "mdi-calendar-range",
      model: 'mdi-database-outline'
    },
    conditionOperators: [
      { value: "$eq", text: "common.fields.operator.$eq",  },
      { value: "$lte", text: "common.fields.operator.$lte" },
      { value: "$gte", text: "common.fields.operator.$gte" },
      { value: "$gt", text: "common.fields.operator.$gt" },
      { value: "$lt", text: "common.fields.operator.$lt" },
      { value: "$contains", text: "common.fields.operator.$contains" },
      { value: "$ne", text: "common.fields.operator.$ne" },
      { value: "$ncontains", text: "common.fields.operator.$ncontains" },
    ],
    conditionLogics: [
      { value: "AND", text: "common.fields.logic.AND" },
      { value: "OR", text: "common.fields.logic.OR" }
    ]
  },
  getters: {
    fields: (state) => {
      return state.fields;
    },
    types: (state) => {
      return state.types;
    },
    iconTypes: (state) => {
      return state.iconTypes;
    },
    conditionOperators: (state) => {
      return state.conditionOperators;
    },
    conditionLogics: (state) => {
      return state.conditionLogics;
    }
  },
};
