import { CHANNEL_SETTINGS } from './../mutations-types'
export default {
  namespaced: true,
  state: {
    channels: {}
  },
  getters: {
    channels: state => {
      return state.channels
    }
  },
  mutations: {
    [CHANNEL_SETTINGS]: (state, channels) => {
      state.channels = channels;
    }
  },
  actions: {
    async setChannels(store) {
      const response = await store.dispatch('request', { url: 'settings.get/channel' }, { root: true });
      store.commit(CHANNEL_SETTINGS, response.data.data);
    }
  }
}