import { CHANNEL_ITEMS } from "./../mutations-types";

export default {
  namespaced: true,
  state: {
    channelItems: [],
    channelTypes: [
      { text: 'WHATSAPP', value: 'WHATSAPP' },
      { text: 'MESSENGER', value: 'MESSENGER' },
      { text: 'SMS', value: 'SMS' },
      { text: 'EMAIL', value: 'EMAIL' },
    ]
  },
  getters: {
    channelItems: (state) => {
      return state.channelItems;
    },
    channelTypes: state => {
      return state.channelTypes;
    }
  },
  mutations: {
    [CHANNEL_ITEMS]: (state, channelItems) => {
      state.channelItems = channelItems;
    },
  },
  actions: {
    setChannelItems(store, channels) {
      const result = [];
      for(const channel of channels) {
        result.push({
          value: channel.identifier,
          text: `${channel.identifier} - ${channel.type}`
        });
      }
      store.commit(CHANNEL_ITEMS, result);
    }
  },
};
