import Vue from 'vue';
import { defaultFilterForms } from './../../constants';
import { FILTERS_TICKET } from "./../mutations-types";

export default {
  namespaced: true,
  state: {
    filters: Vue._.cloneDeep(defaultFilterForms)
  },
  getters: {
    filters: state => {
      return state.filters;
    }
  },
  mutations: {
    [FILTERS_TICKET]: (state, filters) => {
      state.filters = filters;
    },
  },
  actions: {
    setFilters(store, filters) {
      store.commit(FILTERS_TICKET, filters);
    },
    setFilter(store, filter) {
      const filters = store.getters.filters;
      filters[filter.item] = filter.value;
      store.dispatch('setFilters', filters);
    }
  }
}