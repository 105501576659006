<template>
  <v-app class="app">
    <app-bar :drawer="drawer" :options="{ 'clipped-left': true }"></app-bar>
    <v-navigation-drawer
      class="navigation"
      app
      clipped
      left
      v-model="drawer.left"
      :color="
        !$vuetify.breakpoint.mdAndDown ? 'rgba(255,255,255, 0)' : '#ffffff'
      "
    >
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        :style="{ height: windowHeight + 'px' }"
        ref="conversations-wrapper"
        class="infinite-conversation-wrapper"
      >
        <div>
          <v-list>
            <v-list-item>
              <v-list-item-avatar size="40">
                <v-img src="/img/user.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-weight-bold grey--text text--darken-3">
                    {{ fullName }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="mb-2 text-body-2">
                  <span class="secondary-font">
                    {{ $utils.capitalize($auth.user().username) }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-body-2">
                  <span v-if="socketIsAuthenticated">
                    <v-icon color="teal" x-small>mdi-radiobox-marked</v-icon>
                    {{ $i18n.t("online") }}
                  </span>
                  <span v-else>
                    <v-icon color="red" x-small>mdi-radiobox-marked</v-icon>
                    {{ $i18n.t("offline") }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense nav>
            <v-list-item
              exact
              exact-active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'home' }"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-view-dashboard-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.home") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact
              exact-active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'chat' }"
              v-if="$permission.$can('conversation.get', ['user', 'service'])"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-chat-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.conversation") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'tickets' }"
              v-if="$permission.$can('ticket.get', ['user', 'service'])"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-ticket-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.tickets") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact
              exact-active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'provision' }"
              v-if="
                $permission.$can('service.provision.get', ['user', 'service'])
              "
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-wallet-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.provision") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'forms' }"
              v-if="$permission.$can('form.list', ['user', 'service'])"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-form-select</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.forms") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact
              exact-active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'service' }"
              v-if="$permission.$can('service.get', ['user', 'service'])"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-domain</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.service") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact
              exact-active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'user' }"
              v-if="$permission.$can('user.get', 'user')"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-account-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.user") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="secondary--text"
              class="mt-1 mb-2"
              link
              :to="{ name: 'account' }"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>mdi-account-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold secondary-font">
                  {{ $i18n.t("menu.account.title") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              no-action
              class="mb-2"
              :value="isRoute(menu.statistics)"
              v-if="
                $permission.$can('message.export', ['user', 'service']) ||
                $permission.$can('ticket.export', ['user', 'service'])
              "
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon :color="isRoute(menu.statistics) ? 'secondary' : ''"
                    >mdi-chart-box-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'secondary--text': isRoute([
                        'statistic-message',
                        'statistic-ticket',
                      ]),
                    }"
                    class="font-weight-bold secondary-font"
                    >{{ $i18n.t("menu.statistics.title") }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'statistic-message' }"
                v-if="
                  $permission.$can('message.statistic', ['user', 'service'])
                "
                exact
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-message-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.statistics.message") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="$permission.$can('ticket.statistic', ['user', 'service'])"
                active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'statistic-ticket' }"
                exact
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-ticket-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.statistics.ticket") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group
              no-action
              class="mb-5"
              :value="isRoute(menu.settings)"
              v-if="$permission.$canLeastOne(['sla.get', 'working-time.get', 'holiday.get'], ['user', 'service'])"
            >
              <template v-slot:activator>
                <v-list-item-icon class="mr-4">
                  <v-icon :color="isRoute(menu.settings) ? 'secondary' : ''"
                    >mdi-cog-outline</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'secondary--text': isRoute([
                        'sla',
                        'working-time',
                        'permission',
                        'rate-plan',
                      ]),
                    }"
                    class="font-weight-bold secondary-font"
                    >{{ $i18n.t("menu.setting.title") }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item
                active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'working-time' }"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-store-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.working_time") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'holiday-time' }"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-briefcase-clock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.holiday_time") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'sla' }"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-handshake-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.sla") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact
                exact-active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'rate-plan' }"
                v-if="$permission.$can('rate-plan.get', 'user')"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-credit-card-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.rate-plan") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact
                exact-active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'model-data' }"
                v-if="$permission.$can('model-field.list', 'user')"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-database-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.form_model_data") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact
                exact-active-class="secondary--text"
                class="mt-1 mb-2 ml-n4"
                link
                :to="{ name: 'permission' }"
                v-if="$permission.$can('permission.get', 'user')"
              >
                <v-list-item-icon class="mr-4">
                  <v-icon>mdi-security</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold secondary-font">
                    {{ $i18n.t("menu.permission") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view></router-view>
      </v-container>
    </v-main>
    <footer-bar class="justify-end"></footer-bar>
  </v-app>
</template>

<style lang="scss">
.navigation {
  .v-list-item--link {
    &:before {
      background: none !important;
    }
  }
  .v-list-item__title {
    font-size: 0.9rem !important;
  }
}
</style>

<script>
import AppBar from "./../components/layout/app-bar";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapGetters } from "vuex";
import FooterBar from "../components/layout/footer-bar.vue";
export default {
  data: () => ({
    drawer: {
      left: true,
    },
    windowHeight: null,
    windowWidth: null,
    menu: {
      settings: [
        "sla",
        "working-time",
        "holiday-time",
        "permission",
        "rate-plan",
        "model-data"
      ],
      statistics: ["statistic-message", "statistic-ticket"],
    },
  }),
  mounted: function () {
    let self = this;

    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight - 70;

    this.$nextTick(function () {
      window.addEventListener("resize", function () {
        self.windowHeight = window.innerHeight - 70;
      });
      window.addEventListener("resize", function () {
        self.windowWidth = window.innerWidth;
      });
    });

    // connect to socket
    if (!this.socketIsConnected) {
      this.$socket.open();
    }
  },
  methods: {
    isRoute(routes) {
      return routes.includes(this.$route.name);
    },
  },
  computed: {
    fullName() {
      return (
        this.$auth.user().profile.firstName +
        " " +
        this.$auth.user().profile.lastName
      );
    },
    ...mapGetters({
      socketIsConnected: "socketIsConnected",
      socketIsAuthenticated: "socketIsAuthenticated",
    }),
  },
  components: {
    PerfectScrollbar,
    AppBar,
    FooterBar,
  },
};
</script>