import Vue from "vue";
import VueLodash from "vue-lodash";
import {
  each,
  values,
  has,
  map,
  isEmpty,
  find,
  uniq,
  at,
  isEqual,
  uniqueId,
  keys,
  forEach,
  filter,
  findIndex,
  difference,
  cloneDeep,
  pickBy
} from "lodash";

Vue.use(VueLodash, {
  lodash: {
    each,
    values,
    has,
    map,
    isEmpty,
    find,
    uniq,
    at,
    isEqual,
    uniqueId,
    keys,
    forEach,
    filter,
    findIndex,
    cloneDeep,
    difference,
    pickBy
  },
});
