<template>
  <v-app-bar class flat app color="primary" dark v-bind="options">
    <v-app-bar-nav-icon v-if="!hideNavIcon" @click="drawer.left = !drawer.left"></v-app-bar-nav-icon>
    <v-toolbar-title class="pl-2 text-h6">
      <router-link :to="{name: 'home'}"><span class="font-weight-medium white--text">Omnicanal</span></router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-avatar size="40">
            <v-img src="/img/user.png" :alt="$auth.user().username"></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="40">
              <img src="/img/user.png" :alt="$auth.user().username" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $auth.user().username }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }">
            <v-list-item-title>
              {{ $i18n.t("menu.account.title") }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item :to="{ name: 'logout' }">
            <v-list-item-title>
              {{ $i18n.t("menu.logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Object,
      default: function() {
        return {
          left: true
        }
      }
    },
    options: {
      type: Object,
      default:  function() {
        return  {}
      }
    },
    hideNavIcon: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    fullName() {
      return (
        this.$auth.user().profile.firstName +
        " " +
        this.$auth.user().profile.lastName
      );
    },
  },
};
</script>