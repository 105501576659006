<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">{{ $i18n.t("account.title") }}</div>
    <v-card class="rounded-md mb-4">
      <v-tabs height="60" grow center-active v-model="tab">
        <v-tab class="font-weight-bold" :to="{ name: 'profile' }">
          <v-icon
            :class="{'primary--text': tab === '/account/profile', 'black--text': tab !== '/account/profile'}"
            left
          >mdi-account-tie</v-icon>
          <span
            :class="{'primary--text': tab === '/account/profile', 'black--text': tab !== '/account/profile'}"
          >{{ $t("menu.account.profile") }}</span>
        </v-tab>
        <v-tab :to="{ name: 'security' }">
          <v-icon
            :class="{'primary--text': tab === '/account/security', 'black--text': tab !== '/account/security'}"
            left
          >mdi-shield-account-outline</v-icon>
          <span
            :class="{'primary--text': tab === '/account/security', 'black--text': tab !== '/account/security'}"
          >{{ $t("menu.account.security") }}</span>
        </v-tab>
        <v-tab :to="{ name: 'setting' }">
          <v-icon
            :class="{'primary--text': tab === '/account/setting', 'black--text': tab !== '/account/setting'}"
            left
          >mdi-account-cog-outline</v-icon>
          <span
            :class="{'primary--text': tab === '/account/setting', 'black--text': tab !== '/account/setting'}"
          >{{ $t("menu.account.settings") }}</span>
        </v-tab>
      </v-tabs>
    </v-card>
    <v-row>
      <v-col cols="12" sm="5">
        <v-card class="py-5">
          <v-card-text>
            <div class="d-flex align-center justify-center flex-column flex-wrap">
              <v-avatar color="grey lighten-3" src="/img/user.png" size="100">
                <img src="/img/user.png" alt="John" />
              </v-avatar>
              <div class="mt-2 text-subtitle-1">{{ this.$auth.user().username }}</div>
            </div>

            <v-list>
              <v-list-item class="px-0" v-if="this.$auth.user().lastLogin">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <v-list-item-title class="grey--text text--darken-2 font-weight-bold">
                      <v-icon left>mdi-clock-outline</v-icon>
                      {{
                      $t("users.fields.last_login.title")
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-auto text-right">
                      {{
                      $moment(this.$auth.user().lastLogin).format(
                      "YYYY-MM-DD HH:mm:ss"
                      )
                      }}
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0" v-if="this.$auth.user().lastLogin">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <v-list-item-title class="grey--text text--darken-2 font-weight-bold">
                      <v-icon left>mdi-clock-outline</v-icon>
                      {{
                      $t("users.fields.last_update.title")
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="ml-auto text-right">
                      {{
                      $moment(this.$auth.user().updateAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                      )
                      }}
                    </v-list-item-subtitle>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>


<script>
export default {
  data: () => ({
    tab: null
  })
};
</script>