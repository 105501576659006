const Permission = {};

Permission.install = function(Vue, options) {
  const router = options.router;

  Permission.$granted = null;
  Permission.$roles = {};
  Permission.$vm = null;

  _initBeforeEachRoute();
  _initVm();

  function _initBeforeEachRoute() {
    router.beforeEach((to, from, next) => {
      const user = Vue.prototype.$auth.user();
      if (!user) {
        Permission.$vm.state.loaded = true;
        next();
      } else {
        // initialize permission
        if (!Permission.$granted) {
          Permission.$initialize(user);
        }
        // check permission
        if (to.meta.permission) {
          const permission = to.meta.permission;
          if (!Permission.$can(permission.resource, permission.scope)) {
            next({ name: "403" });
          } else {
            next();
          }
        } else {
          next();
        }
      }
    });
  }

  function _initVm() {
    Permission.$vm = new Vue({
      data: function() {
        return {
          state: {
            loaded: false
          }
        };
      }
    });
  }

  Permission.$initialize = function(user) {
    Permission.$granted = {};
    const granted = user.permissions.granted;
    for (const grant in granted) {
      if (!Permission.$granted[grant]) Permission.$granted[grant] = {};
      for (const g of granted[grant]) {
        Permission.$granted[grant][g.resource] = g.assigned;
      }
    }
    Permission.$vm.state.loaded = true;
  };

  Permission.$can = function(resource, scopes, assigned = null) {
    if (!resource || !scopes) return false;

    if (typeof scopes === "string") {
      scopes = [scopes];
    }

    const granted = scopes.some(scope => {
      if (!Permission.$granted[scope]) return false;

      if (assigned)
        return (
          Object.prototype.hasOwnProperty.call(
            Permission.$granted[scope],
            resource
          ) && Permission.$granted[scope][resource].includes(assigned)
        );

      return Object.prototype.hasOwnProperty.call(
        Permission.$granted[scope],
        resource
      );
    });
    return granted;
  };

  Permission.$canLeastOne = function(resources, scopes, assigned = null) {
    if (!resources || !scopes) return false;
    if(!resources.length) return false;

    if (typeof scopes === "string") {
      scopes = [scopes];
    }

    const grant = resources.some(resource => {
      const granted = scopes.some(scope => {
        if (!Permission.$granted[scope]) return false;
  
        if (assigned)
          return (
            Object.prototype.hasOwnProperty.call(
              Permission.$granted[scope],
              resource
            ) && Permission.$granted[scope][resource].includes(assigned)
          );
  
        return Object.prototype.hasOwnProperty.call(
          Permission.$granted[scope],
          resource
        );
      });
      return granted;
    })
    
    return grant;
  }

  Permission.$ready = function() {
    return Permission.$vm.state.loaded;
  };

  Permission.$clear = function() {
    Permission.$granted = null;
  }

  if (!Vue.$permission) {
    Vue.$permission = Permission;
    Object.defineProperties(Vue.prototype, {
      $permission: {
        get() {
          return Permission;
        }
      }
    });
  }
};

export default Permission;
