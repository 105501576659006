import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import socketIo from "socket.io-client";
import VueSocketIO from "vue-socket.io";

import "./plugins/vuelodash";
import "./plugins/vueaxios";
import "./plugins/vueauth";
import "./plugins/vuemoment";
import "./plugins/vuemeta";
import "./plugins/vuelidate";
import "./plugins/vue-request-builder";
import "./plugins/vue-permission";
import "./plugins/vuedraggable";
import "./plugins/vuejsonviewer";
import "./plugins/vue-autolinker";
import "./plugins/vue-sanitize";
import "./plugins/vueutils";
import "./plugins/vue-mask";

import store from "./store";

/* prism editor compoenent css */
import "vue-prism-editor/dist/prismeditor.min.css"

Vue.config.productionTip = false;

// socket io
Vue.use(
  new VueSocketIO({
    debug: process.env.VUE_SOCKET_DEBUG == 'true' ? true : false,
    connection: socketIo(process.env.VUE_APP_BASE_URL, { autoConnect: false }),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
