import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from './../i18n';

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);


export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      light: {
        primary: "#051131",
        secondary: "#20639B",
        danger: "#B71C1C",
        success: "#00BFA5",
        warning: "#FF6F00",
        third: "#FFB703",
        fourth: "#FB8500",
        back: "#e8eff5"
      }
    }
  }
});
