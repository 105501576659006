import i18n from './../../i18n';

export default {
  namespaced: true,
  state: {
    billingTypes: [
      { text: i18n.t('services.fields.billing_type.prepaid'), value: 'PREPAID' },
      { text: i18n.t('services.fields.billing_type.postpaid'), value: 'POSTPAID' }
    ]
  },
  getters: {
    billingTypes: state => {
      return state.billingTypes;
    }
  }
}