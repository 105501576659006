import {
  NOTIFICATION,
  NOTIFICATION_ITEM,
  SNACKBAR
} from "./../mutations-types";
import Vue from "vue";

const defaultOptions = {
  status: false,
  timeout: 6000,
  multiLine: false,
  left: false,
  bottom: false,
  right: true,
  top: true,
  vertical: false,
  centered: false,
  color: "rgba(0, 0, 0, 1)",
  absolute: false,
  message: "",
  transition: true,
  windows: false,
  title: null,
  icon: null,
  badge: null
};

export default {
  namespaced: true,
  state: {
    notification: defaultOptions,
    snackbar: null
  },
  getters: {
    notification: state => {
      return state.notification;
    },
    snackbar: state => {
      return state.snackbar;
    }
  },
  mutations: {
    [SNACKBAR](state, snackbar) {
      state.snackbar = snackbar;
    },
    [NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [NOTIFICATION_ITEM](state, data) {
      state.notification[data.item] = data.value;
    }
  },
  actions: {
    notify: (store, options) => {
      // clear old snackbar
      clearTimeout(store.getters.snackbar);

      // merge default notifications options with current options and set notification
      let currentOptions = Object.assign(
        {},
        defaultOptions,
        { status: true },
        options
      );

      const { windows, ...params } = currentOptions;

      if (windows) {
        let notification = false;
        if (!("Notification" in window)) {
          console.log("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have alredy been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          notification = true;
        }

        // Otherwise, we need to ask the user for permission
        else if (
          Notification.permission !== "denied" ||
          Notification.permission === "default"
        ) {
          Notification.requestPermission(permission => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              notification = true;
            }
          });
        }
        if (notification) {
          new Notification(params.title, {
            body: params.message,
            icon: params.icon,
            badge: params.badge,
            vibrate: true
          });
          return;
        }
      }

      store.commit(NOTIFICATION, params);
      // set snackbar
      let snackbar = window.setTimeout(function() {
        store.dispatch("close");
      }, currentOptions.timeout);
      store.commit(SNACKBAR, snackbar);
    },
    close: store => {
      store.commit(NOTIFICATION_ITEM, { item: "status", value: false });
    },
    async request(store, params) {
      return Vue.axios({
        ...params
      });
    }
  }
};
