import i18n from './../../i18n'; 

export default {
  namespaced: true,
  state: {
    statuses: [
      { text: i18n.t('users.fields.status.enabled'), value: 'enabled' },
      { text: i18n.t('users.fields.status.disabled'), value: 'disabled' }
    ]
  },
  getters: {
    statuses: state => {
      return state.statuses
    }
  }
}