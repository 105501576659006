import objectPath from "object-path";
const Utils = {};

const EmailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

Utils.install = function(Vue) {
  Utils.setLocaleBCP47 = function(lang) {
    let locale;
    switch (lang) {
      case "fr":
        locale = "fr-FR";
        break;
      default:
        locale = "en-US";
    }
    return locale;
  };

  /** custom validations */
  Utils.matchScope = function(scope) {
    return /^(user|service)$/.test(scope);
  };

  Utils.validateAccountRole = function(role) {
    return /^(ROLE_ADMIN|ROLE_CLIENT|ROLE_SUPER_ADMIN)$/.test(role);
  };

  Utils.validateBillingType = function(billingType) {
    return /^(PrePaid|PostPaid)$/.test(billingType);
  };

  Utils.pad = function pad(num, size, strict = false) {
    if (parseInt(num) === 0) return strict ? "00" : 0;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };

  Utils.truncate = function(fullStr, len, separator) {
    let strLen;

    if (!Array.isArray(len)) len = [len];
    if (/\s+/.test(fullStr)) {
      strLen = len[0];
    } else {
      strLen = len[1] ? len[1] : len[0];
    }

    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);

    return fullStr.substr(0, frontChars) + separator;
  };

  Utils.getAvatarTitle = function(str) {
    return str
      ? str
          .split(" ")
          .map(([firstLetter]) => firstLetter)
          .filter(
            (_, index, array) => index === 0 || index === array.length - 1
          )
          .join("")
          .toUpperCase()
      : null;
  };

  Utils.capitalize = function(str) {
    return str ? str.charAt(0).toUpperCase() + "" + str.slice(1) : null;
  };

  Utils.humanizeDate = function(date) {
    let start = Vue.prototype.$moment();
    let end = Vue.prototype.$moment(date);
    return end.from(start, true);
  };

  Utils.countTextPart = function(text, size) {
    if (!text) return 0;
    return Math.ceil(text.length / size);
  };

  Utils.hasProperty = function(obj, path) {
    return objectPath.has(obj, path);
  };

  Utils.getPropertyValue = function(obj, path, defaultValue) {
    return objectPath.get(obj, path, defaultValue);
  };

  Utils.setPropertyValue = function(obj, path, value) {
    return objectPath.set(obj, path, value);
  };

  Utils.matchChannelType = function(val) {
    return /^(WHATSAPP|MESSENGER|SMS|EMAIL)$/.test(val);
  };

  Utils.getStringToArray = function(val, separator = ",") {
    if (!val) return [];
    const items = val.trim().split(separator);
    return items.filter((item) => item);
  };

  Utils.matchSeparatedEmail = function(val) {
    if (!val) return false;
    const emails = Utils.getStringToArray(val);
    return emails.every((email) => (email ? EmailReg.test(email) : true));
  };

  Utils.swatchStyle = function(menu, color) {
    return {
      backgroundColor: color,
      cursor: "pointer",
      height: "25px",
      width: "25px",
      borderRadius: menu ? "50%" : "4px",
      transition: "border-radius 200ms ease-in-out",
    };
  };

  Utils.parseRangeDates = function(range, format = "YYYY-MM-DD") {
    if (typeof range != "object") return null;

    if (!range.length) return null;

    if (range.length === 1) {
      return [range[0], range[0]];
    }

    const start = Vue.prototype.$moment(range[0], format);
    const end = Vue.prototype.$moment(range[1], format);

    if (start.isBefore(end)) {
      return [start.format(format), end.format(format)];
    } else {
      return [end.format(format), start.format(format)];
    }
  };

  Utils.stringToColor = function(str) {
    var hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  Utils.downloadUri = function(uri, name) {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  Utils.getTicketFilterQuery = function(filters) {
    let { ticket, ...otherFilters } = filters;

    ticket = Vue._.pickBy(ticket, (t) => {
      if (!t) return false;
      if (typeof t === "object")
        return Object.values(t).some((v) => v != null && v !== "");
      return true;
    });
    otherFilters = Vue._.pickBy(otherFilters, (t) => {
      if (!t) return false;
      if (typeof t === "object")
        return Object.values(t).some((v) => v != null && v !== "");
      return true;
    });

    const f1 = Utils.parseTicketFilterQuery({ data: ticket });
    const f2 = Utils.parseTicketFilterQuery(otherFilters);

    return `${f1}${f2 ? "&" + f2 : ""}`;
  };

  Utils.parseTicketFilterQuery = function(filters) {
    let query = "";
    let index = 1;
    for (const prop in filters) {
      let data = filters[prop];
      if (data == null || data === "") continue;
      if (typeof data === "object") {
        data = JSON.stringify(filters[prop]);
      }
      query += `${index === 1 ? "" : "&"}${prop}=` + data;
      index++;
    }
    return query;
  };

  Utils.lightenColor = function(color, percent) {
    let num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      "#" +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  };

  Utils.objectPath = objectPath;

  if (!Vue.$utils) {
    Vue.$utils = Utils;
    Object.defineProperties(Vue.prototype, {
      $utils: {
        get() {
          return Utils;
        },
      },
    });
  }
};

export default Utils;
