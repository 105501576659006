<template>
  <v-card max-width="500" class="mx-auto mt-10 mb-10" flat color="rgba(255, 255, 255, 0)">
    <v-card-text class="mb-0">
      <div class="grey--text text-center text--lighten-2 text-h1">{{ error }}</div>
      <div class="text-center">
        <div
          class="grey--text text--ligthen-4 title"
        >{{ description }}</div>
      </div>
    </v-card-text>
    <v-card-text class="pt-0 mt-0">
      <div class="text-center">
        <v-btn depressed to="/" large>
          <v-icon left>mdi-home-outline</v-icon>
          {{ $i18n.t('btn.homepage') }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data: () => ({})
}
</script>