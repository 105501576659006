export const defaultFormsProgressState = {
  text: null,
  value: null,
  color: "#000000",
};
export const defaultFormsEvent = {
  title: null,
  type: null,
  enabled: true,
  signal: "POST",
};
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const paginationLimit = parseInt(
  process.env.VUE_APP_TICKET_PAGINATION_LIMIT || "50"
);
export const maxLenghtTicketComment = parseInt(
  process.env.VUE_APP_TICKET_MAXLENGTH_COMMENT || "3000"
);
export const defaultFilterForms = {
  identifier: null,
  author: null,
  contact: null,
  channel: {
    type: null,
  },
  progress: {
    state: null,
  },
  ticket: {},
  sla: {
    value: null
  },
  created: { start: null, end: null }
};
export const defaultFormModelData = {
  id: null,
  identifier: null,
  description: null,
  serviceId: null,
  file: null
}